import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import logo from "../../assets/images/res-logo.png";

import "../../styles/footer.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__box">
          <div className="footer__box-place">
            <a
              href="https://go.2gis.com/2tqo2q"
              className="footer__box-place-text"
            >
              <img src="" alt="" />
              Адрес : АБАЯ 18/1
            </a>
            <p className="footer__box-place-text">
              <img src="" alt="" />
              11:00 до 22:00
            </p>
          </div>
          <div className="footer__box-logo">
            <img src={logo} alt="" />
          </div>
          <div className="footer__box-link">
            <a href="tel:+996553505909" className="footer__box-link-text">
              Связаться с нами <br />
              996 553 505 909
            </a>
          </div>
        </div>
          <div className="ghostcompany">
            <a href="https://ghost-company.online/">Разработано ghost-company.online</a>
          </div>
      </Container>
    </footer>
  );
};

export default Footer;
