import React from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import { Container } from "reactstrap";
import "../styles/about.css";
import Whatsapp__icon from "../assets/images/icon__whatsapp.png"
import Instagramm__icon from "../assets/images/icon__instagram.png"
import Phone__icon from "../assets/images/phone-icon.png"
import Geo__icon from "../assets/images/icon__geo.png"

const About = () => {
  return (
    <Helmet title="О нас">
      <CommonSection title="О нас" />
      <section>
        <Container>
          <div className="about__box">
            <p>
              Суши — это не просто еда, это настоящее произведение искусства. В
              нашем ресторане мы отдаем дань традициям японской кухни, используя
              вековые методы приготовления. Наши повара готовят каждое блюдо с
              любовью и трепетом, используя только самые свежие ингредиенты и
              отборную рыбу. Мы уделяем особое внимание деталям, чтобы каждый
              кусочек суши был приготовлен с мастерством и совершенством. Именно
              поэтому наши суши считаются одними из лучших в Бишкеке.
              <br />
              <br /> Еще одно наше преимущество — это быстрая доставка. Мы
              доставляем заказы в течение часа, за исключением периодов пиковой
              нагрузки. Стоимость доставки составляет всего 0 сомов, но при
              заказе от 800 сомов.
              <br />
              <br /> Кроме того, в нашем ресторане регулярно проводятся акции и
              скидки. Например, при самовывозе вы можете получить скидку 5%. Это
              выгодно для клиентов, которые живут рядом с рестораном и могут
              забрать заказ самостоятельно.
            </p>
            <div className="about__contact">
              <a href="tel:+996553505909" className="about__contact-link">
                Можете нам позвонить по этому номеру: 0553 505 909 <img src={Phone__icon} alt="" />
              </a>
              <a href="https://wa.me/996500505909" className="about__contact-link">
                Можете нам написать на вотсап этому номеру: 0500 505 909 <img src={Whatsapp__icon} alt="" />
              </a>
              <a href="https://www.instagram.com/moresushi_kg/" className="about__contact-link">
                Можете посетить наш инстаграмм аккаунт <img src={Instagramm__icon} alt="" />
              </a>
              <a href="https://go.2gis.com/2tqo2q" className="about__contact-link">
                Адрес нашего заведения: Абая 18/1 <img src={Geo__icon} alt="" />
              </a>
            </div>
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
