import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import CommonSection from "../components/UI/common-section/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import CartItem from "../components/UI/cart/CartItem";
import axios from "axios";
import "../styles/checkout.css";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Check from "../assets/images/icon__check.png";


const Checkout = () => {
  const [enterName, setEnterName] = useState("");
  const [enterNumber, setEnterNumber] = useState("");
  const [enterAddress, setEnterAddress] = useState("");
  const [enterHouse, setEnterHouse] = useState("");
  const [isDelivery, setIsDelivery] = useState(true); // Изначально установите на доставку
  const shippingInfo = [];
  const cartTotalAmount = useSelector((state) => state.cart.totalAmount);
  const cartProducts = useSelector((state) => state.cart.cartItems);
  const shippingCost = 0.95;
  const totalAmount = isDelivery
    ? cartTotalAmount
    : Math.floor(cartTotalAmount * shippingCost);

  const dispatch = useDispatch();

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleClearCart = () => {
    dispatch(cartActions.clearCart());
  };

  const handleDeliveryChange = () => {
    setIsDelivery(true); // Выбрана доставка
  };

  const handleSelfPickupChange = () => {
    setIsDelivery(false); // Выбран самовывоз
    setEnterAddress("Самовывоз");
    setEnterHouse("Самовывоз");
  };
  useEffect(() => {
    const isCartEmpty = cartProducts.length === 0;
    document.querySelector(".checkout__btn").disabled = isCartEmpty;
  }, [cartProducts]);
  console.log();
  const submitHandler = (e) => {
    e.preventDefault();
    const userShippingAddress = {
      name: enterName,
      Address: enterAddress,
      phone: enterNumber,
      house: enterHouse,
    };
    handleShow();
    handleClearCart();
    shippingInfo.push(userShippingAddress);
    console.log(handleSelfPickupChange());
    // Добавим код для отправки данных в телеграм
    const message = `
      Новый заказ:
      Имя: ${enterName}
      Номер: ${enterNumber}
      Адрес доставки: ${enterAddress}
      Квартира/Дом: ${enterHouse}
      Метод доставки: ${isDelivery ? "Доставка" : "Самовывоз"}
      Название товара и количество: ${cartProducts
        .map(
          (product) =>
            `\n${" ".repeat(10)}${product.title}: ${product.quantity}шт.`
        )
        .join(" ")}
      Цена: ${totalAmount} сом
    `;

    const botToken = "6653204037:AAH_CeA7SSuYr1icKV8sLVhZuOYuDYzqazU";
    const chatId = "-1002083810444";

    try {
      axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });

      console.log("Form data sent to Telegram successfully!");
    } catch (error) {
      console.error("Error sending form data to Telegram:", error);
    }
    // window.location.reload();
  };

  return (
    <Helmet title="Корзина">
      <CommonSection title="Корзина" />
      <section>
        <Container>
          <Row className="checkout">
            <Col>
              <div className="checkout__bill">
                {cartProducts.length === 0 ? (
                  <h6 className="text-center mt-5">Корзина пустая</h6>
                ) : (
                  cartProducts.map((item, index) => (
                    <CartItem item={item} key={index} />
                  ))
                )}
              </div>
            </Col>
            <Col>
              <h3 className="mb-4">
                Укажите свои данные для завершения заказа
              </h3>
              <form className="checkout__form" onSubmit={submitHandler}>
                <div className="form__group">
                  <input
                    type="text"
                    placeholder="Введите имя"
                    required
                    onChange={(e) => setEnterName(e.target.value)}
                  />
                </div>
                {isDelivery && ( // Отображать поля только при выборе доставки
                  <>
                    <div className="form__group">
                      <input
                        type="text"
                        placeholder="Адрес доставки"
                        required
                        onChange={(e) => setEnterAddress(e.target.value)}
                      />
                    </div>
                    <div className="form__group">
                      <input
                        type="text"
                        placeholder="Номер Квартиры/Дома"
                        required
                        onChange={(e) => setEnterHouse(e.target.value)}
                      />
                    </div>
                  </>
                )}
                <div className="form__group">
                  <input
                    type="number"
                    placeholder="Номер телефона"
                    required
                    onChange={(e) => setEnterNumber(e.target.value)}
                  />
                </div>
                <div className="form__group">
                  <label>
                    <input
                      type="radio"
                      name="deliveryType"
                      checked={isDelivery}
                      onChange={handleDeliveryChange}
                    />
                    Доставка от 800 сом Бесплатная
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="deliveryType"
                      checked={!isDelivery}
                      onChange={handleSelfPickupChange}
                    />
                    Забери заказ сам и получи скидку 5%
                  </label>
                </div>
                <h6 className="d-flex align-items-center justify-content-between mb-3">
                  Сумма заказа: <span>{cartTotalAmount} сом</span>
                </h6>
                <div className="checkout__total">
                  <h5 className="d-flex align-items-center justify-content-between">
                    Итого: <span>{totalAmount} сом</span>
                  </h5>
                </div>
                {/* <input type="radio" name="" id="" />
                <input type="radio" name="" id="" /> */}
                <button type="submit" className="addTOCart__btn checkout__btn">
                  Заказать
                </button>
                <section className="modall">
                <Modal show={show} onHide={handleShow} className="modal">
                  <div className="modal1">
                    <img className="modal-img-check" src={Check} alt="" />
                    <h3 className="modal-head">Ваш заказ оформлен</h3>
                    <p className="modal-title">
                      В течение 5 минут оператор с ваши свяжется
                    </p>
                    <Modal.Footer>
                      <Button variant="secondary">
                        <Link to="/">На главную</Link>
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>
                </section>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Checkout;
