import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import AllFoods from "../pages/AllFoods";
import Checkout from "../pages/Checkout";
import Contact from "../pages/Contact";
import About from "../pages/About";
// import AdminPanel from "../templates/AdminPanel";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/foods" element={<AllFoods />} />
      {/* <Route path="/admin" element={<AdminPanel />} /> */}
      <Route path="/About" element={<About/>} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default Routers;
