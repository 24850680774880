import React, { useState, useEffect } from "react";
import axios from "axios";
import Helmet from "../components/Helmet/Helmet.js";
import { Container, Row, Col } from "reactstrap";
import "../styles/hero-section.css";
import "../styles/product-card.css";
import icon_smail from "../assets/images/icon__smail.png";
import "../styles/home.css";
import foodCategoryImg01 from "../assets/images/icon__sushi.png";
import foodCategoryImg02 from "../assets/images/pizza.png";
import foodCategoryImg03 from "../assets/images/icon__drink.png";
import foodCategoryImg04 from "../assets/images/icon__roll.png";
import foodCategoryImg05 from "../assets/images/icon__garnir.png";
import foodCategoryImg06 from "../assets/images/icon__salat.png";
import foodCategoryImg07 from "../assets/images/icon__sup.png";
import foodCategoryImg08 from "../assets/images/icon__set.png";
import foodCategoryImg09 from "../assets/images/icon__supplements.png";
import ProductCart from "../components/UI/product-card/ProductCard.jsx";
import AllFoods from "./AllFoods.jsx";
const Home = () => {
  const [productsSql, setProductsSql] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [category, setCategory] = useState("ALL");

  useEffect(() => {
    axios
      .get("https://api.moresushikg.shop/view_products")
      .then((response) => {
        console.log(response.data);
        setProductsSql(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
    console.log(setProductsSql);
  }, []);

  useEffect(() => {
    if (category === "ALL") {
      setFilteredProducts(productsSql instanceof Array ? productsSql : []);
    } else {
      const filtered =
        productsSql instanceof Array
          ? productsSql.filter((product) => product.category === category)
          : [];
      setFilteredProducts(filtered);
    }
  }, [category, productsSql]);
  return (
    <Helmet title="Home">
      <section>
        <Container>
          <div className="hero__content  ">
            <main className="main">
              <div className="main__head">
                На небе
                <br />
                Только и разговоры,
                <br /> что о <span className="main__head-span">море</span>
              </div>
              <h1 className="main__desc">
                БЕСПЛАТНАЯ ДОСТАВКА <br /> ПО ГОРОДУ!
              </h1>
              <p className="main__title">ОТ 800 СОМОВ!</p>
              <a href="#food" className="main__link">
                <a href="#food">Заказать</a>
              </a>
            </main>
          </div>
        </Container>
      </section>

      {/* <section className="pt-0">
        <Category />
      </section> */}

      <section>
        <Container>
          <Row id="food">
            <Col lg="12" className="text-center">
              <h2 className="menu__desc">Наше меню</h2>
            </Col>
            <div className="food__block">
              <Col lg="12">
                <div className="food__category d-flex align-items-center gap-3">
                  <button
                    className={`all__btn  ${
                      category === "ALL" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("ALL")}
                  >
                    Все
                  </button>

                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Sushi" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Sushi")}
                  >
                    <img src={foodCategoryImg01} alt="" />
                    Суши
                  </button>

                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Roll" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Roll")}
                  >
                    <img src={foodCategoryImg04} alt="" />
                    Роллы
                  </button>

                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Sets" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Sets")}
                  >
                    <img src={foodCategoryImg08} alt="" />
                    Сеты
                  </button>
                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Soup" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Soup")}
                  >
                    <img src={foodCategoryImg07} alt="" />
                    Супы
                  </button>

                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Pizza" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Pizza")}
                  >
                    <img src={foodCategoryImg02} alt="" />
                    Пицца
                  </button>

                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Salad" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Salad")}
                  >
                    <img src={foodCategoryImg06} alt="" />
                    Салаты
                  </button>
                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Garnish" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Garnish")}
                  >
                    <img src={foodCategoryImg05} alt="" />
                    Гарниры
                  </button>

                  <button
                    className={`d-flex align-items-center gap-2 ${
                      category === "Drink" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Drink")}
                  >
                    <img src={foodCategoryImg03} alt="" />
                    Напитки
                  </button>
                  <button style={{whiteSpace: "nowrap"}}
                    className={`d-flex align-items-center gap-2 ${
                      category === "Supplements" ? "foodBtnActive" : ""
                    } `}
                    onClick={() => setCategory("Supplements")}
                  >
                    <img src={foodCategoryImg09} alt="" />
                    Добавки и соусы
                  </button>
                </div>
              </Col>
              {filteredProducts instanceof Array &&
              filteredProducts.length === 0 ? (
                <div className="error">
                  Здесь пока пусто, но мы работаем над этим.
                  <img src={icon_smail} alt="" />
                </div>
              ) : (
                <div className="product-box">
                  {filteredProducts instanceof Array &&
                    filteredProducts.map((product) => (
                      <ProductCart key={product.id} product={product} />
                    ))}
                </div>
              )}
              {/* <div className="product-box">
                  {filteredProducts?.map((product) => (
                    <ProductCart key={product.id} product={product} />
                  ))}
              </div> */}
            </div>
            <AllFoods />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
