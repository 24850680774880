import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
const Helmet = (props) => {
  document.title = "More sushi";
  return (
    <div className="w-100">
      <ReactHelmet>
        <title>More sushi</title>
        <meta
          name="description"
          content="Лучший ресторан суши и роллов в Бишкеке. Наслаждайтесь вкусными блюдами и быстрой доставкой в Бишкеке."
        />
        <meta
          name="keywords"
          content="суши, роллы, японская кухня, ресторан суши, доставка суши, доставка роллов, море суши, море суши kg, moresushi, 
          moresushi shop,
          more sushi,
          more sushi shop,
          суши в бишкеке,
          роллы в Бишкеке,
          пицца в Бишкеке
          moresushi kg,
          moresushikg"
        />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="author" content="More sushi" />
        <meta name="twitter:title" content="Море суши" />
        <meta
          name="twitter:description"
          content="Лучший ресторан суши и роллов в вашем городе. Наслаждайтесь вкусными блюдами и быстрой доставкой."
        />
        <meta property="og:title" content="Море суши" />
        <meta
          property="og:description"
          content="Лучший ресторан суши и роллов в вашем городе. Наслаждайтесь вкусными блюдами и быстрой доставкой"
        />
        <meta property="og:url" content="https://moresushikg.shop/" />
        <meta property="og:type" content="website" />
      </ReactHelmet>
      {props.children}
    </div>
  );
};

export default Helmet;
