import React from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/shopping-cart/cartSlice";
import "../../../styles/product-card.css"
import logo_card from "../../../assets/images/logo__card.png"
import "../../../styles/shopping-cart.css";
import { Link } from "react-router-dom";
import "../../../styles/product-details.css"

const ProductCart = ({ product }) => {
  const dispatch = useDispatch();
  const onAddToCart = (productData) => {
    dispatch(
      cartActions.addItem({
        id: productData.id,
        title: productData.product_name,
        price: productData.price,
        photo_path: productData.photo_path,
        category: productData.category,
        description: productData.description,
      })
    );
    console.log(cartActions);
  };

  const addToCart = () => {
    const { id, product_name, price, photo_path, category, description } = product;

    // Вызываем onAddToCart, передавая данные о продукте
    onAddToCart({
      id,
      product_name,
      price,
      photo_path,
      category,
      description,
    });
  };

  return (
    <div className="card__unit" key={product.id}>
      <img src={logo_card} class="card__unit-logo" alt=""/>
      <img src={`https://api.moresushikg.shop/static/uploads/${product.photo_path}`} className="card__unit-image" alt="" />
      <h4 class="card__unit-desc">{product.product_name}</h4>
      <p class="card__unit-title">{product.description}</p>
      <p class="card__unit-price">{product.price} сом</p>
      <button className="addTOCart__btn card__unit-btn" onClick={addToCart}>
        Заказать
      </button>
    </div>
  );
};

export default ProductCart;

// import React from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { cartActions } from "../../../store/shopping-cart/cartSlice";

// const Cart = () => {
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const totalAmount = useSelector((state) => state.cart.totalAmount);
//   const dispatch = useDispatch();

//   const deleteItem = (id) => {
//     dispatch(cartActions.removeItem(id));
//   };

//   return (
//     <div>
//       <h1>Your Cart</h1>
//       {cartItems.length === 0 ? (
//         <p>Your cart is empty</p>
//       ) : (
//         <table>
//           <thead>
//             <tr>
//               <th>Product Title</th>
//               <th>Price</th>
//               <th>Quantity</th>
//               <th>Delete</th>
//             </tr>
//           </thead>
//           <tbody>
//             {cartItems.map((item) => (
//               <tr key={item.id}>
//                 <td>{item.title}</td>
//                 <td>${item.price}</td>
//                 <td>{item.quantity}</td>
//                 <td>
//                   <button onClick={() => deleteItem(item.id)}>Delete</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       )}
//       <p>Subtotal: ${totalAmount}</p>
//     </div>
//   );
// };

// export default Cart;
