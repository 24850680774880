import React, { useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import "../styles/all-foods.css";
import "../styles/pagination.css";
import Back from "../assets/images/bg-about.png";
import Whatsapp from "../assets/images/icon__whatsapp.png";
import Phone from "../assets/images/phone-icon.png";
import PickUp from "../assets/images/pick-up.png";
import Delivery from "../assets/images/delivery-icon.png";
import { Container } from "reactstrap";
import LogoCard from "../assets/images/res-logo.png"

const AllFoods = () => {
  return (
    <Helmet title="All-Foods">
      <CommonSection title="Доставка"/>
      <Container>
      <section>
        <div className="delivery">
          <div className="delivery__box">
            <img src={Back} alt="" className="delivery__box-img" />
            <div className="delivery__box-link">
              <h3 className="delivery__box-link-title">
                Работаем с 11:00 до 22:00
              </h3>
              <h4 className="delivery__box-link-title">
                Бесплатная доставка <br/> от 800 сом
              </h4>
              <div className="delivery__box-link-block">

              <a href="https://wa.me/996500505909" className="delivery__box-link-icon">
                <img src={Whatsapp} alt="" />
                0500 505 909
              </a>
              <a href="tel:996553505909" className="delivery__box-link-icon">
                <img src={Phone} alt="" />
                0553 505 909
              </a>
              </div>
            </div>
          </div>
        <div className="delivery__info-block">
          <div className="delivery__info-block-card">
            <img className="delivery__info-block-img" src={LogoCard} alt="" />
            <h2 className="delivery__info-block-text">0<br/>сом</h2>
            <h3 className="delivery__info-block-title">
              Бесплатно
            </h3>
            <p className="delivery__info-block-desc">
            Бесплатная доставка по городу при заказе от 800 сом
            </p>
          </div>
          <div className="delivery__info-block-card">
            <img className="delivery__info-block-img" src={LogoCard} alt="" />
            <h2 className="delivery__info-block-text">Мы <br/> работаем</h2>
            <h3 className="delivery__info-block-title">
            с 11:00 до 22:00
            </h3>
            <p className="delivery__info-block-desc">
            7 дней в неделю, 365 дней в году.
            </p>
          </div>
          <div className="delivery__info-block-card">
            <img className="delivery__info-block-img" src={LogoCard} alt="" />
            <img className="delivery__info-block-img-card" src={Delivery} alt="" />
            <h3 className="delivery__info-block-title">
            Стандартная доставка
            </h3>
            <p className="delivery__info-block-desc">
            Сделайте заказ и получите его в течение 90 минут по указанному вами адресу.
            </p>
          </div>
          <div className="delivery__info-block-card">
            <img className="delivery__info-block-img" src={LogoCard} alt="" />
            <img className="delivery__info-block-img-card" src={PickUp} alt="" />
            <h3 className="delivery__info-block-title">
            Самовывоз
            </h3>
            <p className="delivery__info-block-desc">
            Забирайте заказы самостоятельно в удобное время и получите скидку в 5%
            </p>
          </div>
        </div>
        </div>
      </section>
      </Container>
    </Helmet>
  );
};

export default AllFoods;